import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import styled from '@emotion/styled';

import { Button, Input } from 'components';
import notify from 'notify';
import { tryGetFirstError } from 'utils/requests';
import { shareDocumentForSignatureToAll } from '../api';
import PDFEditorContext from '../context';

const AddNoteToEmail = ({ onSuccess }) => {
  const { documentId, setDocumentIsEditable } = useContext(
    PDFEditorContext
  );
  const {
    register,
    formState: { errors, isSubmitting },
    handleSubmit
  } = useForm();

  const submit = async form => {
    try {
        await shareDocumentForSignatureToAll(documentId,form.emailnote,form.emailsToShare);
        setDocumentIsEditable(false);
        notify('Document sent for signature');
        onSuccess();
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  };

  return (
    <StyledNoteAdd className="save-form" onSubmit={handleSubmit(submit)}>
      <Input
        {...register('emailnote')}
        as="textarea"
        error={errors.emailnote?.message}
        label="Email Note"
        placeholder="Enter Accompanying Note for Signature Email"
        className="save-form__input"
      />

      <Input
        {...register('emailsToShare')}
        error={errors.emailsToShare?.message}
        label="Once signed, share with:"
        placeholder="johndoe@brokerage.com,janedoe@brokerage.com"
        className="save-form__input"
      />

      <Button onClick={() => null} disabled={isSubmitting} className="save-form__btn">
        {isSubmitting ? 'Sending...' : 'Send'}
      </Button>
    </StyledNoteAdd>
  );
};

export default React.memo(AddNoteToEmail);

const StyledNoteAdd = styled.form`
  .save-form {
    display: flex;
    flex-direction: column;
    &__input {
      display: flex;
      width: 100%;
      max-width: 300px;
      margin: 0 0 16px;
      &.dropdown {
        flex-direction: column;
      }
    }
    &__btn {
      width: 100%;
      max-width: 300px;
      display: block;
    }
  }
`;
